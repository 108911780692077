.newSection {
  text-align: center;
}

.newSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 112px 64px;
  gap: 40px;
  border-radius: 0px;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.sectionTitle {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(1.6rem, 3.125cqi, 3.125rem) !important;
  font-weight: 400 !important;
  position: relative;
  z-index: 2;
  max-width: 58rem;
  line-height: 1.3 !important;
  text-align: center !important;
  margin: 0 auto !important;
}

.sectionButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.contact {
  background-color: #c7fd06 !important;
  padding: 1.5rem 4.275rem !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  font-family: "Poppins", sans-serif !important;
  color: #000 !important;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s !important;
}

.contact:hover {
  box-shadow: 0 0 10px #c7fd0650, 0 0 20px #c7fd0690, 0 0 30px #c7fd0650,
    0 0 40px #c7fd0650 !important;
  transform: translateY(-3px);
}

@media (max-width: 900px) {
  .newSectionContainer {
    padding: 75px 32px;
  }

  .contact {
    padding: 8px 40px !important;
  }
}
