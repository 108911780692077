.outer-box {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* Garante que cubra toda a altura da viewport */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer-box__container {
  height: 60vh !important;
}

.back {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
  overflow: hidden;
}

.back img {
  max-width: 200px;
}

@media (max-width: 640px) {
  .outer-box {
    height: clamp(50vh, 100cqi, 70vh);
  }
}
