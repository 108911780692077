

/* Carousel Styles */
.carousel-pd .slick-dots {
    display: flex !important;
    justify-content: center;
    padding-top: 15px;
}

.carousel-pd .slick-dots li button::before {
    margin-top: 0 !important;
}

/* Image Container Styling */
.carouselImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
    margin: 0 15px !important;
}

.carouselImageContainer img {
    width: 500px;
    height: 400px;
    border-radius: 4px;
    object-fit: cover;
    /* Define a maximum height */
}



/* Media Queries for Responsive Design */
@media (max-width: 992px) {
    .carouselImageContainer img {
        height: 272px;
    }
}

@media (max-width: 600px) {
    .carouselImageContainer img {
        height: 240px;
    }
}


.carouselImageContainer {
    text-align: center;
    font-size: 16px; /* Tamanho padrão */
  }
  
  .carouselImageContainer img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 600px) {
    .carouselImageContainer {
      font-size: 12px; /* Tamanho menor para telas pequenas */
    }
  }
  
  @media (max-width: 480px) {
    .carouselImageContainer {
      font-size: 10px; /* Fonte ainda menor para dispositivos muito pequenos */
    }
  }
  