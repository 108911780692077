.newSection {
  text-align: center;
}

.svgContainer {
  max-width: 1920px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -100px;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 0;
  overflow: hidden;
  box-shadow: inset 1000px 0 1000px -1000px #93BB04,
    /* Sombra interna à esquerda */
    inset -1000px 0 1000px -1000px #779704;
  /* Sombra interna à direita */
}


.svg {
  position: absolute;
  transition: all 0.5s ease;
}

.svg1 {
  left: -5%;
  animation: float1 15s ease-in-out infinite;
}

.svg2 {
  right: -5%;
  animation: float2 18s ease-in-out infinite;
}

@keyframes float1 {

  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }

  25% {
    transform: translateY(-20px) rotate(5deg);
  }

  50% {
    transform: translateY(0) rotate(0deg);
  }

  75% {
    transform: translateY(20px) rotate(-5deg);
  }
}

@keyframes float2 {

  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }

  33% {
    transform: translateY(30px) rotate(-8deg);
  }

  66% {
    transform: translateY(-30px) rotate(8deg);
  }
}

@media (max-width: 1256px) {
  .svgContainer {
    display: none;
  }
}

.newSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 112px 64px;
  gap: 40px;
  border-radius: 0px;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: brightness(0.5) contrast(1.1);
  z-index: 1;
}

.sectionTitle,
.sectionSubtitle {
  color: white;
  position: relative;
  z-index: 2;
}

.sectionTitle {
  font-weight: 400 !important;
  font-family: 'Anton', sans-serif !important;
}

.sectionSubtitle {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.sectionButtons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
}

.contact {
  font-weight: 700 !important;
  background-color: #c7fd06 !important;
  padding: 10px 55px !important;
  font-size: 1.5rem !important;
  border-radius: 15px !important;
  font-family: 'Poppins', sans-serif !important;
  color: black !important;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s !important;
}

.contact:hover {
  box-shadow: 0 0 10px #c7fd0650, 0 0 20px #c7fd0690, 0 0 30px #c7fd0650,
    0 0 40px #c7fd0650 !important;
  transform: translateY(-3px);
}

@media (max-width: 900px) {
  .newSectionContainer {
    padding: 75px 32px;
  }

  .sectionTitle {
    font-size: 2rem !important;
  }

  .sectionSubtitle {
    font-size: 1.2rem !important;
  }

  .contact {
    font-size: 1.2rem !important;
    padding: 8px 40px !important;
  }
}