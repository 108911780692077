.go-beyond {
  margin-bottom: clamp(4rem, 10.625cqi, 10.625rem);
}

.go-beyond__container {
  display: flex !important;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 0 1.5rem !important;
  align-items: center;
}

.go-beyond__container h2 {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(2rem, 3.75cqi, 3.75rem);
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 1.875rem;
}

.go-beyond__container p {
  color: #fff;
  font-size: clamp(1.2rem, 1.5cqi, 1.5rem);
  font-weight: 400;
  line-height: 1.3;
}

.go-beyond__container img {
  width: 100%;
  max-width: 36.625rem;
  aspect-ratio: 586 / 440;
}

@media screen and (max-width: 960px) {
  .go-beyond__container {
    flex-direction: column;
  }
}
