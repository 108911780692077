/* src/style.css */

.video-section {
  position: relative;
  width: 100%;
  height: 100%;
  /* Ajuste conforme necessário */
  overflow: hidden;
  z-index: 1;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background: url(../../../assets/images/Lightbox.png) no-repeat;
  background-size: contain !important;
  /* Ajuste a opacidade conforme necessário */
  background-size: 100%;
  background-position: center;
  color: white;
  text-align: center;
  filter: brightness(0.6);
}

.pre-video {
  display: grid;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Ajuste a opacidade conforme necessário */
  background-size: 100%;
  background-position: center;
  text-align: center;
  padding: 40px;
}

.pre-video-left {
  display: grid;
  align-items: end;
  justify-content: start;
}

.video-title {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700 !important;
}

.video-description {
  font-size: 1rem;
}

.play-button {
  position: absolute;
  background: white !important;
  border: none;
  color: #000 !important;
  font-size: 3rem;
  cursor: pointer;
  z-index: 10;
}
