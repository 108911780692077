.section-title {
    width: 100%;
    font-family: "Anton", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 60px !important;
    line-height: 118%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #C7FD06, #779704);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text;
    color: transparent;
    text-transform: uppercase;
}