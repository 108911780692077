.css-1pwqxr6-MuiFormControl-root-MuiTextField-root {
  margin-top: 5px !important;
}

/* style.css */

/* Estilos para o container principal */

/* Estilos para o banner */

.banner-container {
  max-width: 1400px;
  height: 100%;
  display: flex;
  justify-content: right;
  flex-direction: row;
  text-align: center;
  color: black;
  padding-left: 10px;
  flex-wrap: wrap;
  align-content: space-around;
  /* gap: 80px; */
  margin: 100px auto 100px auto;
  position: relative;
  overflow: hidden;
  z-index: 1;

}

@media (max-width: 937px) {
  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .banner-container {
    gap: 40px;
  }
}

.text-banner {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Anton", "Red Hat Display", "Poppins", "Roboto", sans-serif !important;
  font-size: 3.75 !important;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  padding-right: 80px;
}

@media (max-width: 937px) {
  .text-banner {
    text-align: center;
    padding-right: 0px;
  }
}

.green-container {
  background-color: #C7FD06;
  /* Cor do fundo verde */
  box-shadow: inset -20px 0 30px rgba(0, 0, 0, 0.3);
  /* Sombra interna na direita */
}


/* Estilos para o botão de preenchimento do formulário */
.banner-button {
  background-color: #c7fd06;
  color: black;
  opacity: 0.65;
  padding: 5px 30px 5px 30px !important;
  border-radius: 10px !important;
}

.banner-button-2 {
  background-color: #6D8B03;
  color: black;
  opacity: 0.65;
  padding: 5px 30px 5px 30px !important;
  border-radius: 10px !important;
  margin: 40px 0 !important;
  display: none;
}

@media (max-width: 937px) {
  .banner-button {
    display: none !important;
  }
}

.banner-button2:hover {
  background-color: #b3e505;
}

.banner-button2 {
  background-color: #c7fd06;
  color: black;
  opacity: 0.65;
  padding: 5px 30px 5px 30px !important;
  border-radius: 10px !important;
}

@media (min-width: 937px) {
  .banner-button2 {
    display: none !important;
  }

  .banner-button-2 {
    display: none !important;
  }
}

.banner-button:hover {
  background-color: #b3e505;
}

/* Estilos para o diálogo */
.dialog-title {
  color: #c7fd06;
}

.input-label {
  color: #c7fd06;
  margin-top: 16px;
  display: block;
}

.checkbox-label {
  color: #c7fd06;
}

.snackbar-alert {
  width: 100%;
}

/* carrossel */
.custom-carousel-discover .slick-prev,
.custom-carousel-discover .slick-next {
  display: none !important;
}


.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #c7fd06;
}

/* svg */
.svg-container {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-img {
  height: 100%;
  width: 482px;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.content-img img {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
}

.light-effect {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(170, 214, 10, 0.301) 50%,
      rgba(255, 255, 255, 0) 100%);
  height: 200%;
  width: 100%;
  animation: moveLight 4s linear infinite;
  z-index: 1;
}

@keyframes moveLight {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
}

.slick-slide img {
  object-fit: cover;
}

@media (max-width: 992px) {
  .svg-container {
    height: 600px !important;
  }

}