.values {
  background-image: url("../../../assets/images/waves.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.values__container {
  display: flex !important;
  justify-content: center;
  gap: 2rem 5.5rem;
  flex-wrap: wrap;
}

.values__card {
  text-align: center;
  background-color: transparent !important;
  border-radius: 0 !important;
}

.values__card img {
  margin-bottom: 2rem;
}

.values__card h3 {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(2rem, 3.75cqi, 3.75rem);
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 0.9375rem;
}

.values__card p {
  color: #fff;
  font-size: clamp(0.8rem, 1.125cqi, 1.125rem);
  font-weight: 400;
  line-height: 1.3;
  max-width: 24rem;
}

.values__card h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #fff;
}

.values__card-info {
  display: flex;
  gap: clamp(3.2rem, 6.25cqi, 6.25rem);
  text-align: left;
}

.values__content {
  padding-bottom: 2.5rem !important;
  position: relative;
}

.values__content::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  max-width: 18.5rem;
  height: 7px;
  background-color: #c7fd06;
}

@media screen and (max-width: 480px) {
  .values__card-info {
    flex-direction: column;
  }
}
