.plantation {
  margin-bottom: clamp(4.6rem, 6.125cqi, 6.125rem);
}

.plantation__container {
  display: flex !important;
  gap: clamp(3rem, 6.625cqi, 6.625rem);
  justify-content: space-between;
  padding: 0 1.5rem !important;
}

.plantation__container span {
  display: block;
  color: #fff;
  font-size: clamp(1.2rem, 1.5cqi, 1.5rem);
  font-weight: 400;
  line-height: 1.3;
  font-family: "Amsterdam", sans-serif !important;
  font-style: italic;
  margin-bottom: 1.875rem;
  position: relative;
  z-index: 1;
}

.plantation__container h2 {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(2rem, 3.75cqi, 3.75rem);
  font-weight: 400;
  line-height: 1.3;
  max-width: 30rem;
  margin-bottom: 2.2rem;
  position: relative;
  z-index: 1;
}

.plantation__container p {
  color: #fff;
  font-size: clamp(1.2rem, 1.5cqi, 1.5rem);
  font-weight: 400;
  line-height: 1.3;
  position: relative;
  z-index: 1;
}

.plantation__container p:not(:last-child) {
  margin-bottom: 2rem;
}

.plantation__images {
  position: relative;
}

.plantation__images img:first-child {
  width: clamp(16rem, 34.625cqi, 34.625rem);
  aspect-ratio: 555 / 403;
  position: relative;
  z-index: 1;
}

.plantation__images img:last-child {
  position: absolute;
  top: -76px;
  right: -270px;
  z-index: 0;
}

@media screen and (max-width: 960px) {
  .plantation__container {
    flex-direction: column;
    align-items: center;
  }

  .plantation__images img:last-child {
    display: none;
  }
}
