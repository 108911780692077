.partners {
  font-family: 'poppins' !important;
}

.partners .mainContainer {
  width: auto !important;
  height: 100%;
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.partners .cardsContainer {
  display: flex;
  gap: 10px;
  margin: auto !important;
  justify-content: center;
  flex-direction: row;
  /* Por padrão, exibe em linhas */
}

/* Para dispositivos menores */
@media (max-width: 1080px) {
  .partners .cardsContainer {
    flex-direction: column !important;
    /* Altera para colunas em telas menores */
  }
}

.partners .card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  padding: 10px;
  margin-bottom: 10px;
}

.partners .carouselImage {
  width: 100%;
  height: 260px !important;
  object-fit: cover;
  border-radius: 8px;
}

.partners .cardContent {
  padding: 10px;
  margin: auto;
}

.partners .cardTitle {
  display: flex;
  color: var(--Padro-Black, var(--Color-Brand-black, #000));
  /* Título/H5 */
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 24px */
}

.partners .cardSubtitle {
  color: var(--Padro-Black, var(--Color-Brand-black, #000));
  /* REGULAR/LABELS REGULAR */
  display: block;
  /* Altere de flex para block */
  text-align: left;
  /* Certifique-se de que o texto esteja à esquerda */
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 120% !important;
  /* 16.8px */
  letter-spacing: -0.42px !important;
}

.partners .detailsButtonWrapper {
  width: 100%;
  display: inline-flex !important;
  justify-content: flex-end !important;
}

.partners .detailsButton {
  border-radius: 10px !important;
  background-color: #c7fd06 !important;
  color: #546A03 !important;
  font-weight: bold !important;
}

.partners .mainButton {
  background-color: #c7fd06 !important;
  color: #000 !important;
  padding: 10px 20px !important;
  border-radius: 8px;
  font-size: 1.2rem !important;
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.partners .title-new {
  font-family: "Anton", sans-serif !important;
  font-size: 60px !important;
  font-weight: 500 !important;
  line-height: 58px;
  background: linear-gradient(90deg, #C7FD06, #779704);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px 
}

.partners .subtitle {
  line-height: 28px !important;
  display: flex;
  width: auto;
  max-width: 550px;
  margin: auto !important;
  justify-content: center !important;
  flex-shrink: 0;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  text-align: center;
  margin-bottom: auto !important;
  color: #666;
  font-size: 1.2rem;
}

.partners .custom-carousel .slick-prev,
.partners .custom-carousel .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 110px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 100;
  margin: auto;
}

.partners .custom-carousel .slick-prev:before,
.partners .custom-carousel .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.partners .custom-carousel .slick-prev_2,
.partners .custom-carousel .slick-next_2 {
  position: absolute;
  top: 50%;
  width: 40px !important;
  height: 40px !important;
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  border-radius: 50% !important;
  outline: none;
  z-index: 100;
}

.partners .custom-carousel .slick-prev_2:before,
.partners .custom-carousel .slick-next_2:before {
  position: absolute;
  z-index: 1;
  width: 28px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  font-size: 40px;
  color: #c7fd06;
  opacity: 0.75;
  /* Corrigido de 10 para 0.75 */
  line-height: 0.8;
  margin-left: -4px;
  right: 3px;
  display: flex;
  justify-content: center;
}

/* .slick-prev { 
 left: -43px;}
.slick-next {
  left: 195px;
} */

.partners .slick-slide {
  padding: 0 0px 0 0;
  margin: auto;
}

.partners .custom-carousel .custom-slide {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: baseline;
}

.partners .svgIconWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.partners .svgIconWrapper .svgIcon {
  fill: var(--Padro-Verde-Vext, #c7fd06);
  stroke-width: 0.5px;
  stroke: var(--Color-Brand-black, #000);
}

.partners .svgDetailsWrapper {
  display: flex;
  justify-content: flex-start;
  /* Alinhe o SVG à esquerda */
  width: 57%;
  /* Garante que o container ocupe a largura total */
  margin-top: 10px;
  /* Espaço entre o subtítulo e o novo SVG */
}

.partners .detailsSvgIcon {
  fill: var(--Padro-Verde-Vext, #c7fd06);
  stroke-width: 0.5px;
  /* stroke: var(--Color-Brand-black, #000); */
}

.partners .detailssvg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  /* background-color: #f0f0f0; */
  width: 58%;
  flex-wrap: wrap;
}

.partners .textPair {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partners .textPair1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.partners .nunbsvg,
.partners .textsvg {
  display: block;
  color: var(--Padro-Black, var(--Color-Brand-black, #000));
  text-align: center;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 120%;
  letter-spacing: normal !important;
  padding: 0px 0px 0px 0px !important;
}

.partners .slick-dots {
  text-align: center;
}

.partners .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.partners .slick-dots li.slick-active button:before {
  color: #c7fd06;
}

.partners .slick-dots li button {
  font-size: 0;
  line-height: 0;
  /* display: none; */
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.partners .slick-dots li button:before {
  background-color: transparent;
  color: gray;
  font-size: 9px;
  margin-top: 40px !important;
}

.partners .hide-dots .slick-dots {
  display: none !important;
}

.partners .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}