.appBar {
  background-color: rgba(0, 0, 0, 0.75) !important;  /* Preto com 75% de opacidade */
  transition: height 0.3s ease-in-out !important;
  /* Transparente */
}
.appBarScrolled {
  height: 64px !important;
  background-color: rgba(0, 0, 0, 10) !important;  /* Preto com 75% de opacidade */
}

.toolbar {
  justify-content: space-between;
  height: 100%;
}

.logoBox {
  display: grid;
  place-items: center;
}

.logoTypography {
  flex-grow: 1;
  color: var(--text-primary-color);
  /* Certifique-se de definir esta variável no seu CSS ou substitua-a por um valor concreto */
  font-weight: 700;
}

.logoImage {
  height: 40px;
  margin-top: 10px;
}

.navButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 96px; /* Adiciona espaço de 16px entre os botões */
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif !important;
}

.navButton {
  font-weight: 500 !important;
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  border-bottom: 2px solid transparent; /* Começa com a borda invisível */
  transition: border-color 0.3s ease; /* Transição suave ao passar o mouse */
}

.navButton:hover {
  border-bottom: 2px solid #c7fd06; /* Linha verde ao passar o mouse */
}

.actionBox {
  display: grid;
  place-items: center;
}

.custom-button {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 20px !important;
  gap: 8px !important;
  border: 1px solid #c7fd06 !important;
  border-radius: 5px !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
}
.button-text {
  height: 17px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  color: #c7fd06 !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
}

.custom-button-vext {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 20px !important;
  gap: 8px !important;
  border-radius: 5px !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  background-color: #c7fd06 !important;
}
.button-text-vext {
  height: 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 120% !important;
  color: #000000 !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
}

.container {
  display: flex;
  gap: 16px;
}

@media (max-width: 500px) {
  .container {
  display: block;
  gap: 5px;
  }
}