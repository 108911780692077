.partners {
  margin-bottom: clamp(5rem, 13.25cqi, 13.25rem);
}

.partners__container {
  padding: 0 1.5rem !important;
}

.partners__info {
}

.partners__info span {
  display: block;
  margin-bottom: 1.875rem;
  color: #fff;
  font-size: clamp(1.2rem, 1.5cqi, 1.5rem);
  font-weight: 400;
  line-height: 1.3;
  font-family: "Amsterdam", sans-serif !important;
  font-style: italic;
}

.partners__info h2 {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(2rem, 3.75cqi, 3.75rem);
  font-weight: 400;
  line-height: 1.3;
  max-width: 39.4375rem;
  margin-bottom: clamp(3.2rem, 4.625cqi, 4.625rem);
}

.partners__card-area {
  position: relative;
}

.partners__deco {
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
}

.partners__card-area:last-child .partners__deco {
  left: unset;
  right: 0;
}

.partners__card {
  width: 100%;
  max-width: 63.75rem;
  padding: 1.625rem 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(2.8rem, 3.75cqi, 3.75rem);
  margin: 0 auto;
  background-color: transparent !important;
  background-image: linear-gradient(
    to bottom,
    rgba(84, 106, 3, 0.1),
    rgba(165, 208, 6, 0.1)
  );
  border-radius: 1.5rem !important;
  position: relative !important;
  z-index: 1;
}

.partners__card-container {
  margin-bottom: clamp(3.2rem, 6.25cqi, 6.25rem);
}

.partners__card h3 {
  color: #c7fd06;
  font-family: "Anton", sans-serif !important;
  text-transform: uppercase;
  font-size: clamp(2rem, 3.75cqi, 3.75rem);
  font-weight: 400;
  line-height: 1.3;
  max-width: 30.25rem;
  margin: 0;
}

.partners__card p {
  color: #fff;
  font-size: clamp(0.8rem, 1.125cqi, 1.125rem);
  font-weight: 400;
  line-height: 1.3;
  max-width: 30.25rem;
}

.partners__card-image {
  width: 100%;
  max-width: 20.25rem;
  aspect-ratio: 324 / 412;
  border-radius: 1.75rem;
  object-fit: cover;
}

@media screen and (max-width: 500px) {
  .partners__card {
    flex-direction: column !important;
  }
}
