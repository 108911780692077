.joinville-text {
    width: 100%;
    /* Ajuste a largura para ocupar 100% no container */
    font-family: "Anton", sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 60px !important;
    line-height: 118%;
    margin-bottom: 60px !important;
    text-transform: uppercase;
    color: #8DB404;
    text-transform: uppercase;
    padding-left: 16%;
    /* Esse padding também será ajustado para telas menores */
}
.carousel{
    background-color: #FCFEF5 !important;
}
/* Ajustes para dispositivos móveis */
@media (max-width: 600px) {
    .joinville-text {
        width: 100%;
        /* Reduz o tamanho da fonte no mobile */
        padding-left: 0 !important;
        /* Remove o padding excessivo no mobile */
        text-align: center !important;
        /* Centraliza o texto no mobile */
        margin-bottom: 30px !important;
        /* Reduz o espaçamento inferior */
    }

    .carousel {
        margin-bottom: 120px;
    }
}


/* style.css */

.overlay {
    transition: opacity 0.5s ease;
}

.overlay-enter {
    opacity: 1;
}

.overlay-exit {
    opacity: 0;
}