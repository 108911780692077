.outer-box {
  background-color: black;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* Garante que cubra toda a altura da viewport */
  z-index: 1000;
}

.container {
  position: relative;
}

.background {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.box {
  width: 50%;
  padding: 16px;
  text-align: left;
  position: relative;
  z-index: 0;
}

.title {
  font-size: clamp(1.7rem, 2.5cqi, 2.5rem) !important;
  text-align: left !important;
}

@media (max-width: 500px) {
  .title {
    text-align: center !important;
  }
}

.highlight {
  font-size: clamp(2rem, 3cqi, 3rem) !important;

  text-transform: uppercase;
  font-family: "Anton" !important;
  font-weight: 400;
  color: #c7fd06;
}

.imgImob {
  height: 45px;
}

.emphasis {
  font-weight: 900;
}

.subtitle {
  font-weight: 400;
  font-size: 1.2rem;
  /* Base font size */
}

.css-1oqqzyl-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.actionButton {
  position: relative !important;
  z-index: 2 !important;
  /* Ajustado para garantir que o botão esteja acima de outros elementos */
  font-weight: 700 !important;
  background-color: #c7fd06 !important;
  padding: 18px 35px !important;
  margin-top: 35px !important;
  font-size: 1.5rem !important;
  border-radius: 15px !important;
  font-family: "Roboto" !important;
  color: black !important;
  /* Base font size */
  transition: background-color 0.3s, box-shadow 0.3s !important;
}

.actionButton:hover {
  box-shadow: 0 0 20px #c7fd0699, 0 0 40px #c7fd0699, 0 0 60px #c7fd0690,
    0 0 80px #c7fd0690 !important;
}

.floating-image {
  position: absolute;
  right: 16px;
  width: 500px;
  height: auto;
  overflow: hidden;
  z-index: 1;
}

.floating-image img {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 900px) {
  .floating-image {
    display: none;
  }
  .box {
    width: 89%;
  }

  .css-1oqqzyl-MuiContainer-root {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media (max-width: 600px) {
  .box {
    width: 100%;
    margin: 0 auto;
    padding: 8px !important;
    text-align: center;
  }

  .imgImob {
    height: 25px;
  }

  .title {
    font-size: 2rem !important;
  }

  .partners .subtitle {
    font-size: 18px !important;
  }

  .css-1oqqzyl-MuiContainer-root {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .actionButton {
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }
}
