.card-details {
    color: black;
    padding: 20px;
    text-align: center;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    box-shadow: 2px 2px 5px #5555553b;
    color: white;
}

.button-details {
    background-color: #c7fd06 !important;
    color: #000 !important;
    margin-top: 40px !important;
    font-weight: 600 !important;  
    height: 50px;
}
.card-details .p{
    font-size: 2rem !important;
    font-weight: 700;
}
.button-details:hover {
    box-shadow: 0 0 20px #c7fd0650, 0 0 40px #c7fd0699, 0 0 60px #c7fd0650, 0 0 80px #c7fd0650 !important;
}

.description-enter {
    opacity: 0;
    max-height: 0;
    transition: opacity 300ms, max-height 300ms;
}

.description-enter-active {
    opacity: 1;
    max-height: 1000px;
    /* Ajuste conforme necessário */
}

.description-exit {
    opacity: 1;
    max-height: 1000px;
    /* Ajuste conforme necessário */
    transition: opacity 300ms, max-height 300ms;
}

.description-exit-active {
    opacity: 0;
    max-height: 0;
}

@media (max-width: 600px) {
    .img-details {
        width: 65px;
    }
}